export default class Tab {
  constructor() {
    this.tabs = document.querySelectorAll("ul.nav.nav-pills li.nav-item a");
    this.tabContents = document.querySelector(".tab-content");

    this.init();
  }

  init() {
    if (this.tabs.length === 0 || this.tabContents === null) return;
    this.tabs.forEach((tab) => {
      tab.addEventListener("click", (e) => {
        e.preventDefault();

        // 全てのタブとタブの中身を一度非表示に
        this.tabs.forEach((tab) => {
          tab.classList.remove("active");
        });
        this.tabContents.querySelectorAll(".tab-pane").forEach((content) => {
          content.classList.remove("active", "show");
        });

        // 選択されたタブとタブの中身を表示する
        tab.classList.add("active");
        this.tabContents
          .querySelector(tab.getAttribute("href"))
          .classList.add("active", "show");
      });
    });
  }
}
