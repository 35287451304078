export default class Header {
  constructor() {
    this.navbarFixed = document.querySelector(".navbar-fixed");
    this.excludeScrollEventPages = [
      "/products",
      "/sets",
      "/product_categories",
      "/search",
      "/contact",
      "/cart",
      "/order",
      "/customer",
    ];
    this.init();
  }

  init() {
    // excludeScrollEventPagesにマッチするページでは反転ヘッダーを初期表示にする
    if (this.isTargetPage()) {
      this.navbarFixed.classList.add("navbar-shrink");
    } else {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset >= 10) {
          this.navbarFixed.classList.add("navbar-shrink");
        } else {
          this.navbarFixed.classList.remove("navbar-shrink");
        }
      });
    }
    if (location.pathname === "/") {
      this.navbarFixed.querySelector(".nav-news").classList.add("d-none");
    }
  }

  isTargetPage() {
    return (
      this.excludeScrollEventPages.some((pathName) => {
        return location.pathname.indexOf(pathName) !== -1;
      }) && location.pathname.indexOf("customer_term") === -1
    );
  }
}
